"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
export default class HeaderStickyComponent extends DcBaseComponent {
  constructor(props) {
    super(props);
    __publicField(this, "headerSticky", () => {
      if (Math.ceil(window.pageYOffset) >= this.startPosition && window.pageYOffset > 0) {
        this.addClassToElement();
      } else {
        this.removeClassToElement();
      }
    });
    __publicField(this, "addClassToElement", () => {
      if (this.isSticky)
        return;
      this.element.classList.add("sticky");
      this.isSticky = true;
    });
    __publicField(this, "removeClassToElement", () => {
      if (!this.isSticky)
        return;
      this.element.classList.remove("sticky");
      this.isSticky = false;
    });
    this.isSticky = false;
    this.startPosition = 0;
  }
  static getNamespace() {
    return "header-sticky";
  }
  onInit() {
    setTimeout(() => {
      if (document.body.classList.contains("hotel-page")) {
        const pinSpacer = document.querySelector(".pin-spacer");
        const hotelHeroSection = document.querySelector(".hotel-details-page__hero");
        if (pinSpacer !== null && hotelHeroSection !== null) {
          this.startPosition = pinSpacer.offsetHeight - hotelHeroSection.offsetHeight;
        }
      }
    }, 10);
    this.headerSticky();
    this.addListener(window, "scroll", this.headerSticky);
  }
}
